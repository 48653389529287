<!-- 考试二级分类 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210)
  }

  label {
    margin-bottom: 0;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    secondListPage,
    updateSecondTypeQyzt,
    deletSecondType,
    addSecondType,
    editSecondType,
    easyList,
  } from "@/api/admin/base/mainType.js"
  import {
    getDicts
  } from "@/api/common/dict.js";
  import YzSelect from "@/components/form/yzSelect.vue";
  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,
      YzSelect
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "考试二级类别",
            active: true
          }
        ],
        exportTitle: "考试二级类别导出",
        importTitle: "考试二级类别导入",
        checked: true,
        currentPage: 1,
        showmodal: false,
        tableList: [],
        dialogTitle: "添加考试类别",
        module: "KSEJFL",
        classList: [],
        selectObj: {},
        // value: "",
        addForm: {
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          sfms: true,
          qyzt: true,
          sfgdks: false,
          pxh: 0,
          mcsx: "",

        },
        disabled: false,
        dict: {},
        select: {},
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
        }
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deletSecondType(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        secondListPage(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj) {
        let {
          sid,
          qyzt
        } = obj
        updateSecondTypeQyzt({
          sid: sid,
          qyzt: qyzt
        }).then()
      },
      // 添加类别
      addMaintype() {
        let formDate = {
          lbbm: this.addForm.lbbm,
          lbmc: this.addForm.lbmc,
          flbm: this.addForm.flbm,
          flmc: this.addForm.flmc,
          sfms: this.addForm.sfmf,
          qyzt: this.addForm.qyzt,
          sfgdks: this.addForm.sfgdks,
          pxh: this.addForm.pxh,
          mcsx: this.addForm.mcsx
        }
        if (formDate.mcsx && formDate.flmc) {
          addSecondType(formDate).then(res => {
            if (res.status) {
              this.success()
            }

          })
        } else {
          this.$message({
            type: 'warning',
            message: '二级分类名称和名称缩写不能为空!'
          });
        }

      },
      editMaintype() {
        let formDate = {
          lbbm: this.addForm.lbbm,
          lbmc: this.addForm.lbmc,
          flbm: this.addForm.flbm,
          flmc: this.addForm.flmc,
          sfms: this.addForm.sfmf,
          qyzt: this.addForm.qyzt,
          sfgdks: this.addForm.sfgdks,
          pxh: this.addForm.pxh,
          mcsx: this.addForm.mcsx,
          sid: this.addForm.sid
        }
        if (formDate.mcsx && formDate.flmc) {
          editSecondType(formDate).then(res => {
            if (res.status) {
              this.success()
            }
          })
        } else {
          this.$message({
            type: 'warning',
            message: '二级分类名称和名称缩写不能为空!'
          });
        }
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.addForm = {
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          sfms: true,
          qyzt: true,
          sfgdks: false,
          pxh: 0,
          mcsx: "",
        }
        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加考试类别"
        this.addForm = {
          lbbm: "",
          lbmc: "",
          flbm: "",
          flmc: "",
          sfms: true,
          qyzt: true,
          sfgdks: false,
          pxh: 0,
          mcsx: "",
        }
        this.getBm(this.module, "ej").then(e => {
          this.addForm.flbm = e
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改考试类别"
        this.disabled = true
      },
      submit() {
        if (this.addForm.sid) {
          this.editMaintype()
        } else {
          this.addMaintype()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      // 考试大类
      getListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      searchClick() {
        this.pageData.pageNum = 1
        this.getlistByParam()
      },
      getlistByParam() {
        secondListPage({
          ...this.pageData,
          ...this.select
        }).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total;
          }
        })
      },
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }



    },
    mounted() {
      this.getList()
      this.getListPage()
      getDicts("ksdl").then((res) => {
        this.dict = res;
      });
    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList" style="flex: 1;">
                <el-select class=" mr-2 blueBorder" clearable style="width: 10%;" placeholder="考试类别"
                  v-model="select.lbbm" size="small">
                  <el-option :value="item.lbbm" v-for="item in classList" :key="item.value" :label="item.lbmc">
                    {{item.lbmc}}
                  </el-option>
                </el-select>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button variant="outline-info" class="flexList mr-2 condition" @click="addItem"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加类别</b-button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module:module,condition:select})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module,condition:select})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th style="width: 7%;">分类编码</th>
                    <th>分类名称</th>
                    <th style="width:15%">所属类别</th>
                    <th style="width:8%">是否启用</th>
                    <th style="width:5%;text-align: center;">滚动考试</th>
                    <th style="width:5%;text-align: center;">允许免试</th>
                    <th style="width:10%">名称缩写</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.flbm}}</td>
                    <td>
                      <div class="font-blue" @click='editItem(obj)'>{{obj.flmc}}</div>
                    </td>
                    <td> {{obj.lbmc}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td class="text-center">{{obj.sfgdks==true?'是':'否'}}</td>
                    <td class="text-center">{{obj.sfms==true?'是':'否'}}</td>
                    <td>{{obj.mcsx}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(obj)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.flmc)"></i></td>
                  </tr>
                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 添加类别（二级 ）-->
    <b-modal id="addClass" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3 flexList"><label>所属类别：</label>
              <div class=" col-sm-10 p-0 flexList">
                <yz-select :dict="dict.ksdl" class="w-100" :bound="['lbbm', 'lbmc']" v-model="addForm">
                </yz-select>

              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3 flexList"><label>分类编码：</label>
              <div class="col-sm-10  p-0">
                <input type="text" placeholder="请输入分类编码" :disabled="disabled" maxlength="10" v-model="addForm.flbm"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3 flexList"><label>分类名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" maxlength="50" placeholder="请输入分类名称" v-model="addForm.flmc"
                  class="form-control w-100 h30 " />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">滚动考试：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="sfgdks" id="exampleRadios2" value=true
                    v-model="addForm.sfgdks" class="form-check-input"><label for="exampleRadios2"
                    class="form-check-label">是
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="sfgdks" id="exampleRadios2" value=false
                    v-model="addForm.sfgdks" class="form-check-input"><label for="exampleRadio2"
                    class="form-check-label">否</label></div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">允许免试：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="sfms" id="exampleRadios3" value="true"
                    v-model="addForm.sfms" class="form-check-input"><label for="exampleRadios3"
                    class="form-check-label">允许
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="sfms" id="exampleRadios3" value=false
                    v-model="addForm.sfms" class="form-check-input"><label for="exampleRadio3"
                    class="form-check-label">不允许</label></div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2 w-15"><input type="radio" name="qyzt" id="exampleRadios1" value=true
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadios1"
                    class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" value=false
                    v-model="addForm.qyzt" class="form-check-input"><label for="exampleRadio1"
                    class="form-check-label">不启用</label></div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3 flexList"><label>排序数值：</label>
              <div class="col-sm-10 p-0 flexList">
                <b-form-input id="number" value="" type="number" name="number" class="w-100 h30" v-model="addForm.pxh"
                  placeholder="序号越小排序越靠前">
                </b-form-input>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3 flexList"><label>名称缩写：</label>
              <div class="col-sm-10 p-0 flexList">
                <input value="" type="text" class="w-100 h30 form-control" maxlength="50" v-model="addForm.mcsx"
                  placeholder="英文缩写，主要用于标记报名数据库，不重复">

              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束-->
  </Layout>
</template>
